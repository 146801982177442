<template>
  <div class="second-step">
    <h4 class="text-dark-darken">{{ title }}</h4>
    <div class="delivery-details mt-4">
      <form ref="customer" @submit.prevent="handleSubmit" autocomplete="off">
        <b-form-group :label="`${$t('form.labels.name')}:` | capitalize" label-for="name" size="sm">
          <b-form-input
            v-model="form.name"
            :placeholder="$t('spot.placeholders.name')"
            size="sm"
            id="name"
            type="text"
            required
          />
        </b-form-group>
        <b-form-group
          :label="`${$t('form.labels.document')}:` | capitalize"
          label-for="document"
          size="sm"
        >
          <b-form-input
            v-model="form.document"
            :placeholder="$t('form.placeholders.document')"
            size="sm"
            id="document"
            type="text"
            required
          />
        </b-form-group>
        <b-form-group
          v-if="formTextbox"
          :label="`${$t('form.labels.nfeKey')}:` | capitalize"
          label-for="invoice_key"
          size="sm"
        >
          <b-form-input
            v-model="form.invoice_key"
            :placeholder="$t('form.placeholders.nfeKey')"
            size="sm"
            id="invoice_key"
            type="text"
            required
          />
        </b-form-group>
        <b-form-group
          v-if="formTextbox"
          :label="`${$t('form.labels.invoice_number')}:` | capitalize"
          label-for="invoice_key"
          size="sm"
        >
          <b-form-input
            v-model="form.invoice_number"
            :placeholder="$t('form.placeholders.invoice_number')"
            size="sm"
            id="invoice_key"
            type="text"
          />
          <b-alert show variant="secondary" class="p-2 mt-2">
            <small>
              <em>{{ $t('form.describeTexts.invoice_number') }}</em>
            </small>
          </b-alert>
        </b-form-group>

        <b-form-group
          v-if="formTextbox"
          :label="`${$t('form.labels.nfValue')}:` | capitalize"
          label-for="nfe_value"
          size="sm"
        >
          <b-form-input
            v-model="form.nfe_value"
            :placeholder="$t('form.placeholders.nfValue')"
            size="sm"
            id="nfe_value"
            type="text"
          />
        </b-form-group>

        <b-form-group
          :label="`${$t('form.labels.email')}:` | capitalize"
          label-for="email"
          size="sm"
        >
          <b-form-input
            v-model="form.email"
            :placeholder="$t('spot.placeholders.email')"
            size="sm"
            id="email"
            type="email"
            :required="form.phone.length ? false : true"
          />
        </b-form-group>
        <b-form-group
          :label="`${$t('form.labels.mobilePhone')}:` | capitalize"
          label-for="phone"
          size="sm"
        >
          <b-form-input
            v-model="form.phone"
            :placeholder="$t('spot.placeholders.phone')"
            v-mask="'(##) #####-####'"
            size="sm"
            id="phone"
            type="text"
            :required="form.email.length ? false : true"
          />
        </b-form-group>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'second-step',
  props: {
    title: {
      type: String,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    formTextbox: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {},
  data() {
    return {
      form: {
        name: '',
        document: '',
        invoice_key: '',
        email: '',
        phone: '',
        nfe_value: '',
      },
    };
  },
  mounted() {
    this.form = this.customer;
  },
  watch: {
    formValues(value) {
      if (
        value.name.length > 5 &&
        value.document.length > 5 &&
        (!!value.email.match(/(\S+)@(\S+)\.(\S+)/) || value.phone.length >= 14) &&
        (value.document.length > 11 ? !!value.invoice_key : true)
      ) {
        this.$emit('is-sendable', true);
      } else {
        this.$emit('is-sendable', false);
      }
    },
  },
  computed: {
    sizeText() {
      let text = '';

      switch (this.packageSize) {
        case '1':
          text = 'smallBoxSize';
          break;
        case '2':
          text = 'mediumBoxSize';
          break;
        case '3':
          text = 'largeBoxSize';
          break;
        default:
          text = 'smallBoxSize';
      }

      return this.$t(`spot.${text}`);
    },
    formValues() {
      return { ...this.form };
    },
  },
  methods: {
    handleSubmit() {
      this.form.nfe_value = this.form.nfe_value ? this.form.nfe_value : 0;
      this.form.phone = this.form.phone.replace(/\D/g, '');
      this.form.document = this.form.document.replace(/\D/g, '');
      this.$emit('next-step', this.form, this.form.invoice_key);
    },
    changeValue() {
      this.$emit('next-step', {
        q_vol: Number(this.q_vol),
        volume: Number(this.volume),
      });
    },
  },
};
</script>

<style lang="scss">
.second-step {
  label {
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--dark-darken);
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    &::placeholder {
      color: var(--transition);
    }
  }
}
</style>
